import React, { useRef, useEffect, useState } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import * as faceDetection from '@mediapipe/face_detection';
import { Camera } from '@mediapipe/camera_utils';
import S3 from 'aws-sdk/clients/s3.js';


const s3 = new S3({
  endpoint: "https://sin1.contabostorage.com/warehouse-attendance",
  accessKeyId: "f21439d90fd89c15e621536b99d3ec8b",
  secretAccessKey: "8acfc04a00cfa868bdfb5b53622fd97d",
  s3BucketEndpoint: true,
  signatureVersion: "v4",
});

const uploadBase64File = async (base64String, fileName, contentType, folder = '') => {
  const base64Data = base64String.replace(/^data:image\/\w+;base64,/, "");
  const binaryString = atob(base64Data);
  const len = binaryString.length;
  const bytes = new Uint8Array(len);

  for (let i = 0; i < len; i++) {
    bytes[i] = binaryString.charCodeAt(i);
  }

  const key = folder ? `${folder}/${fileName}` : fileName;

  const params = {
    Bucket: 'school-attendance',
    Key: key,
    Body: bytes.buffer,
    ContentEncoding: 'base64',
    ContentType: contentType
  };

  try {
    const data = await s3.upload(params).promise();
    console.log('File uploaded successfully:', data.Location);
    return data.Location;
  } catch (error) {
    console.error('Error uploading file:', error);
    throw error;
  }
};
const VideoCapture = ({ selectedClass }) => {
  const videoRef = useRef(null);
  const canvasRef = useRef(null);
  const [name, setName] = useState('');
  const [studentName, setStudentName] = useState('');
  const [loading, setLoading] = useState(false);
  const [canCheckIn, setCanCheckIn] = useState(false);
  const [canCheckOut, setCanCheckOut] = useState(false);
  let lastCallTime = Date.now(); // Initialize with the current time

  useEffect(() => {
    startVideoCapture();
  }, []);

  useEffect(() => {
    if (studentName) {
      checkAttendanceStatus();
    }
  }, [studentName]);

  const startVideoCapture = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ video: { facingMode: 'user' } });
      if (videoRef.current) {
        videoRef.current.srcObject = stream;
      }
      initializeFaceDetection();
    } catch (err) {
      console.error('Error accessing camera:', err);
    }
  };

  const initializeFaceDetection = () => {
    const videoElement = videoRef.current;

    const faceDetectionInstance = new faceDetection.FaceDetection({
      locateFile: (file) => `https://cdn.jsdelivr.net/npm/@mediapipe/face_detection/${file}`,
    });

    faceDetectionInstance.setOptions({
      model: 'short',
    });

    const camera = new Camera(videoElement, {
      onFrame: async () => {
        await faceDetectionInstance.send({ image: videoElement });
      },
      width: 540,
      height: 540,
    });

    faceDetectionInstance.onResults(onResults);

    camera.start();
  };

  const onResults = (results) => {
    const videoElement = videoRef.current;
    const canvasElement = canvasRef.current;
    const canvasCtx = canvasElement.getContext('2d');

    // Set canvas dimensions to match video element dimensions
    canvasElement.width = videoElement.videoWidth;
    canvasElement.height = videoElement.videoHeight;

    canvasCtx.save();
    canvasCtx.clearRect(0, 0, canvasElement.width, canvasElement.height);
    canvasCtx.drawImage(results.image, 0, 0, canvasElement.width, canvasElement.height);

    if (results.detections.length > 0) {
      results.detections.forEach((detection) => {
        const { xCenter, yCenter, width, height } = detection.boundingBox;
        canvasCtx.strokeStyle = 'red';
        canvasCtx.lineWidth = 2;
        canvasCtx.strokeRect(
          xCenter - width / 2,
          yCenter - height / 2,
          width,
          height
        );
      });

      const now = Date.now();
      if (now - lastCallTime >= 3000) { // Check if 3 seconds have passed
        lastCallTime = now; // Update last call time
        const base64 = canvasElement.toDataURL('image/jpeg');
        sendFrameToBackend(base64);
      }
    }

    canvasCtx.restore();
  };

  const sendFrameToBackend = (imageData) => {
    const formData = new FormData();
    formData.append('image_data', imageData);

    axios.post("https://attendance-test-api.betterpw.live/upload", formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
      .then((response) => {
        setStudentName(response?.data?.recognized_names[0] || 'Unknown');
      })
      .catch((error) => {
        console.error("Error uploading image:", error);
      });
  };

  const captureMultipleFrames = async () => {
    if (!name) {
      toast.error("Please enter the employee's name.");
      return;
    }

    setLoading(true);
    const video = videoRef.current;
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    const formData = new FormData();
    formData.append('name', name);

    const getBase64Images = async () => {
      if (video.readyState === video.HAVE_ENOUGH_DATA) {
        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;

        for (let i = 0; i < 5; i++) {
          ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
          const base64 = canvas.toDataURL('image/jpeg');
          formData.append(`image${i}`, base64.split(',')[1]);
          await new Promise((resolve) => setTimeout(resolve, 1000));
        }
        sendFormDataToBackend(formData);
      } else {
        setTimeout(getBase64Images, 3000);
      }
    };

    getBase64Images();
  };

  const sendFormDataToBackend = async (formData) => {
    try {
      const response = await axios.post('https://attendance-test-api.betterpw.live/register', formData);
      toast.success("Registered Employee Successfully");
      const base64Image = canvasRef.current.toDataURL('image/png');
      await uploadBase64File(base64Image, `${name}.png`, 'image/png', selectedClass);
      setName('');
    } catch (error) {
      toast.error("Error occurred while Registering Student");
      console.error('Error sending form data:', error);
    }
    setLoading(false);
  };

  const checkAttendanceStatus = async () => {
    try {
      const response = await axios.post('https://attendance-test-api.betterpw.live/attendance-status', { student_name: studentName }, {
        headers: {
          'Content-Type': 'application/json'
        }
      });
      const status = response.data;
      setCanCheckIn(status.canCheckIn);
      setCanCheckOut(status.canCheckOut);
      if (status.checkedOutToday) {
        toast.info('You have already checked out today. Please check in tomorrow.');
      }
    } catch (error) {
      console.error('Error checking attendance status:', error);
      setCanCheckIn(true);
    }
  };

  const checkIn = async () => {
    try {
      await axios.post('https://attendance-test-api.betterpw.live/check-in', { student_name: studentName }, {
        headers: {
          'Content-Type': 'application/json'
        }
      });
      toast.success('Checked In successfully');
      checkAttendanceStatus();
    } catch (error) {
      toast.error('Failed to check in');
      console.error('Error checking in:', error);
    }
  };

  const checkOut = async () => {
    try {
      await axios.post('https://attendance-test-api.betterpw.live/check-out', { student_name: studentName }, {
        headers: {
          'Content-Type': 'application/json'
        }
      });
      toast.success('Checked Out successfully');
      checkAttendanceStatus();
    } catch (error) {
      toast.error('Failed to check out');
      console.error('Error checking out:', error);
    }
  };

  return (
    <div className='bg-[#1B2124] text-white flex flex-col h-screen'>
      <div className='flex px-10 gap-8 pb-6 h-[calc(100vh-6rem)] flex-row'>
        <div className='max-w-[540px] py-10 rounded-xl px-24 bg-black w-full h-full flex flex-col items-center justify-center'>
          <p className='text-center font-semibold text-lg my-5'>Facial Recognition Attendance</p>
          <div className='flex-1 bg-green-400 overflow-hidden mx-auto rounded-2xl relative'>
            <video className='w-full h-full object-cover rounded-xl' ref={videoRef} autoPlay playsInline></video>
            <h3 className='absolute text-lg shadow-lg shadow-[#5A4BDA]/50 top-0 left-1/2 -translate-x-1/2 rounded-xl bg-white/80 rounded-t-none px-2 py-1 min-w-40 text-black text-center'>
              {studentName}
            </h3>
          </div>
          <canvas ref={canvasRef} style={{ display: 'none' }}></canvas>
          <form className='flex mt-5 w-full rounded-lg overflow-hidden border h-fit border-gray-300 items-center' onSubmit={(e) => { e.preventDefault(); captureMultipleFrames(); }}>
            <input type='text' placeholder='Enter Employee Name' value={name} onChange={(e) => setName(e.target.value)} className='text-base flex-1 py-2.5 px-2 bg-transparent outline-none text-white h-full' />
            <button type='submit' className='text-base text-blue-600 px-2'>{loading ? 'Adding...' : 'Add'}</button>
          </form>
          <p className='text-center text-sm my-2 text-gray-300'>Please stand in front of the webcam in a way such that your face must be clearly visible.</p>
        </div>
        {studentName !== 'Unknown' && (
          <div className='flex flex-col items-center justify-center w-full'>
            <div className='mt-5'>
              {studentName && (
                <>
                  <img src={`https://attendance-test-api.betterpw.live/student-images/${studentName}/0.jpg`} alt={studentName} className='w-64 h-64 object-cover rounded-full mx-auto' />
                  <p className='text-center mt-4'>Hello, {studentName}!</p>
                </>
              )}
            </div>
            <div className='flex mt-4 gap-4'>
              {canCheckIn && (
                <button onClick={checkIn} className='bg-blue-500 text-white py-2 px-4 rounded'>Check In</button>
              )}
              {canCheckOut && (
                <button onClick={checkOut} className='bg-red-500 text-white py-2 px-4 rounded'>Check Out</button>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default VideoCapture;
