import React, { useState, useEffect } from 'react';
import axios from 'axios';

const AdminPanel = () => {
  const [attendance, setAttendance] = useState([]);

  useEffect(() => {
    getAttendanceData();
  }, []);

  const getAttendanceData = async () => {
    try {
      const response = await axios.get('https://attendance-test-api.betterpw.live/attendance');
      setAttendance(response.data);
    } catch (error) {
      console.error('Error getting attendance data:', error);
    }
  };

  return (
    <div className='bg-[#1B2124] text-white flex flex-col h-screen p-4'>
      <h1 className='text-2xl mb-4'>Admin Panel</h1>
      <div className='flex flex-wrap gap-4 overflow-y-auto'>
        {attendance.map((entry, index) => (
          <div key={index} className='bg-black w-full p-4 rounded-lg'>
            <h2 className='text-xl mb-2'>Date: {entry.date}</h2>
            {entry.attendance.map((student, idx) => (
              <div key={idx} className='flex justify-between items-center mb-4'>
                <div className='flex items-center gap-4'>
                  {student.name && (
                    <img 
                      src={`http://localhost:4003/student-images/${student.name.split('/').pop()}/0.jpg`} 
                      alt={student.name.split('/').pop()} 
                      className='w-16 h-16 object-cover rounded-full' 
                    />
                  )}
                  <div>
                    <p className='text-lg font-semibold'>{student.name.split('/').pop()}</p>
                    <p className='text-sm'>Check-In: {student.checkIn || 'N/A'}</p>
                    <p className='text-sm'>Check-Out: {student.checkOut || 'N/A'}</p>
                  </div>
                </div>
                <div className='flex gap-2'>
                  <span className={`px-2 py-1 rounded-lg ${student.absent ? 'bg-red-500' : 'bg-green-500'}`}>
                    {student.absent ? 'Absent' : 'Present'}
                  </span>
                </div>
              </div>
            ))}
          </div>
        ))}
        {attendance.length === 0 && <p className='text-white my-auto text-center w-full'>No Attendance Data</p>}
      </div>
    </div>
  );
};

export default AdminPanel;
