import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { IoMdHome } from 'react-icons/io';
import { Link } from 'react-router-dom';

function Navbar({ onSelectClass }) {
  // Retrieve the selected class from localStorage or default to 'warehouse1'
  const [selectedClass, setSelectedClass] = useState(localStorage.getItem('selectedClass') || 'warehouse1');

  useEffect(() => {
    // Call onSelectClass with the initial selected class
    onSelectClass(selectedClass);
  }, []);

  const handleClassChange = (e) => {
    const newClass = e.target.value;
    setSelectedClass(newClass);

    // Store the selected class in localStorage
    localStorage.setItem('selectedClass', newClass);

    axios.post('https://attendance-test-api.betterpw.live/update-class', { class_name: newClass })
      .then(response => {
        console.log(response.data.message);
        onSelectClass(newClass);
        // window.location.reload(); // Reload the page to reflect the class change
      })
      .catch(error => {
        console.error('Error updating class:', error);
      });
  };

  return (
    <div className='flex justify-between items-center px-32 h-20 p-4 bg-[#1B2124] text-white'>
      <div className='flex items-center gap-8'>
        <a href="/">
          <IoMdHome color='white' size={30} />
        </a>
        <p>Attendance System</p>
      </div>
      <div className='flex items-center gap-4'>
        <select
          className='text-white bg-transparent w-52 py-1.5 border border-white rounded-md px-3'
          value={selectedClass}
          onChange={handleClassChange}
        >
          <option value='warehouse1'>Warehouse 1</option>
          <option value='warehouse2'>Warehouse 2</option>
          <option value='warehouse3'>Warehouse 3</option>
        </select>
        <Link to="/admin" className='text-white px-4 py-2 bg-blue-600 rounded-lg'>Admin Panel</Link>
      </div>
    </div>
  );
}
export default Navbar;
